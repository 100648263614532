import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('.OpinionSection');

		if( container ) {

			function opinionSectionSlick() {

				var carousel = container.querySelector('.opinionSection__opinions');
				var buttonNext = container.querySelector('.SlickControl__button--next');
				var buttonPrev = container.querySelector('.SlickControl__button--prev');

				var carouselValueContent = container.querySelector('.paged__value');
				var carouselValue = parseInt( carouselValueContent.innerText );
				
				var carouselMaxContent = container.querySelector('.paged__max');
				var carouselMax = parseInt( carouselMaxContent.innerText );

				$(carousel).slick({
					slidesToScroll: 1,
					arrows: false,
					variableWidth: true,
					rows: false,
					responsive: [
						{
							breakpoint: 801,
							settings: {
								slidesToShow: 1,
								variableWidth: false,
								dots: true,
							}
						}
					]
				});

				// -- slick change slides -- //

					function slickSlidesNext() {

						carouselValue += 1;

						if( carouselValue > carouselMax ) {

							carouselValue = 1;

						} 

						carouselValueContent.innerText = carouselValue;

					}

					function slickSlidesPrev() {

						carouselValue -= 1;

						if( carouselValue <= 0 ) {

							carouselValue = carouselMax;

						}

						carouselValueContent.innerText = carouselValue;

					}

					buttonNext.addEventListener('click', function() {

						$(carousel).slick('slickNext');

						// slickSlidesNext();

					});

					buttonPrev.addEventListener('click', function() {

						$(carousel).slick('slickPrev');

						// slickSlidesPrev();

					});	

					$(carousel).on('beforeChange', function( event, slick, currentSlide, nextSlide ) {

						if( currentSlide < nextSlide ) {

							slickSlidesNext();

						} else if( nextSlide == 0 ) {

							carouselValue = 1;
							carouselValueContent.innerText = carouselValue;

						} else {

							slickSlidesPrev();

						}

					});

				// -- END -- //

			}
			opinionSectionSlick();


		}

	},
	finalize() {

	}

}