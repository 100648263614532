// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import contact from '../src/Pages/Contact/Contact';
import customerform from '../src/Pages/CustomerForm/CustomerForm';
import archiveproducts from '../src/Pages/ArchiveProducts/ArchiveProducts';
import singleproduct from '../src/Pages/SingleProduct/SingleProduct';

LazyLoad.init();
Subpage.init();

const routes = new Router({
	homepage,
	contact,
	customerform,
	archiveproducts,
	singleproduct
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});