import BoxMobileSlider from '../../Sections/BoxMobileSlider/BoxMobileSlider';
import ProductsSection from '../../Sections/ProductsSection/ProductsSection';
import OpinionSection from '../../Sections/OpinionSection/OpinionSection';
import NewsletterSection from '../../Sections/NewsletterSection/NewsletterSection';

export default {
	init() {

		BoxMobileSlider.init();
		ProductsSection.init();
		OpinionSection.init();
		NewsletterSection.init();
    	//console.log('Homepage js');
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
