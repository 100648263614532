import BoxMobileSlider from '../../Sections/BoxMobileSlider/BoxMobileSlider';
import OpinionSection from '../../Sections/OpinionSection/OpinionSection';
import NewsletterSection from '../../Sections/NewsletterSection/NewsletterSection';

export default {

	init() {

		BoxMobileSlider.init();
		OpinionSection.init();
		NewsletterSection.init();

	},	
	finalize() {

	}

}