export default {

	init() {

		// function mobileMenu() {

		// 	var hamburger = document.querySelector('#hamburger');
		// 	var mobileMenu = document.querySelector('section.MobileMenu');
		// 	var body = document.querySelector('body');

		// 	if( hamburger && mobileMenu ) {

		// 		var mobileMenuContent = mobileMenu.querySelector('.mobileMenu__content');
		// 		var mobileMenuCloseButton = mobileMenuContent.querySelector('.content__close');

		// 		hamburger.addEventListener('click', function() {

		// 			mobileMenu.classList.add('active');
		// 			body.classList.add('disableScroll');

		// 		});

		// 		mobileMenu.addEventListener('click', function() {

		// 			mobileMenu.classList.remove('active');
		// 			body.classList.remove('disableScroll');

		// 		});

		// 		mobileMenuCloseButton.addEventListener('click', function() {

		// 			mobileMenu.classList.remove('active');
		// 			body.classList.remove('disableScroll');
					
		// 		});

		// 		mobileMenuContent.addEventListener('click', function(e) {

		// 			e.stopPropagation();

		// 		});

		// 	}

		// }
		// mobileMenu();


		// -- Header hide -- //

			function headerHideShowScroll() {

				var header = document.querySelector('header.Header--fixed');
				if(header) {
					var headerHeight = header.offsetHeight;

					var lastPosition = 0;
					var action = 'bottom';

					document.addEventListener('scroll', function(e) {

						if( window.scrollY < lastPosition ) {

							if( header.classList.contains('hide') ) {

								header.classList.remove('hide');

							}

						} else if( headerHeight < window.scrollY && !header.classList.contains('hide') ) {

							header.classList.add('hide');

						}

						lastPosition = window.scrollY;

					});

					window.addEventListener('resize', function() {

						headerHeight = header.offsetHeight;

					});
				}

			}
			headerHideShowScroll();

		// -- END -- //

	},
	finalize() {

	}

}