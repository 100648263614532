import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('main.CustomerForm');

		if( container ) {

			// -- Refresh symptoms button on select change -- //

				function selectRefresh() {

					var select = container.querySelector('.CustomerForm__select');

					var xhr = new XMLHttpRequest();
					var xmlDoc = null;
					var currentUrl = window.location.origin + window.location.pathname;

					select.addEventListener('change', function() {

						xhr = new XMLHttpRequest();
						xhr.onreadystatechange = function() {

							if( xhr.readyState == 4 && xhr.status == 200) {

		            			var xmlDoc = document.implementation.createHTMLDocument("example");
								xmlDoc.documentElement.innerHTML = xhr.responseText;

								container.querySelectorAll('.CustomerForm__options .CustomerForm__option').forEach( function( box,index ) {

									box.remove();

								});

								xmlDoc.querySelectorAll('.CustomerForm__options .CustomerForm__option').forEach( function( box,index ) {

									container.querySelector('.CustomerForm__options').insertBefore(box, container.querySelector('.CustomerForm__options').lastChild.nextSibling );

								});

								window.history.pushState('' ,'Jessie', currentUrl+'?cat='+ select.value ); 

								changeSymptomsCount();

		        			} 

						}

						xhr.open( 'GET', currentUrl +'?cat='+ select.value +'&ajax=yes', true );
						xhr.send();

					});

				}
				selectRefresh();

			// -- END -- //


			// -- Window on back -- //

				const listener = (href) => {

					window.location.href = window.location.href;

				};

				window.addEventListener('popstate', listener);

			// -- END -- //


			// -- Symptoms Action on button -- //

				function symptomsAction() {

					document.addEventListener('click', function(e) {

						if( e.target.classList.contains('CustomerForm__option') ) {

							if( e.target.classList.contains('active') ) {

								e.target.classList.remove('active');

							} else {

								e.target.classList.add('active');

							}

							changeSymptomsCount();

						}

					});
				}
				symptomsAction();

			// -- END -- //


			// -- SubmitButton change counter -- //

				var submitButton = container.querySelector('.CustomerForm__actionBar .button__submit');
				var symptoms;
				var symptomsValue = [];
				var symptomsInput = container.querySelector('input[name="symptoms"]');
				function changeSymptomsCount() {

					symptomsValue = [];
					symptoms = container.querySelectorAll('.CustomerForm__option.active');

					if( symptoms.length > 0 ) {

						submitButton.querySelector('span').innerText = 'Continue with '+ symptoms.length;
						submitButton.classList.remove('disable');

						symptoms.forEach( function(single,index) {


							symptomsValue.push(single.getAttribute('data-id') );


						});

						symptomsInput.value = symptomsValue.join(',');

					} else {

						submitButton.querySelector('span').innerText = 'Continue';
						submitButton.classList.add('disable');
						symptomsInput.value = '';

					}

				}

			// -- END -- //


			// -- Form submit -- //

				function formSubmit() {

					var form = container.querySelector('form.CustomerForm__actionBar');
					var submitButton = form.querySelector('.button__submit');
					var values = form.querySelector('input[name="symptoms"]');

					form.addEventListener('submit', function(e) {

						e.preventDefault();

						if( !submitButton.classList.contains('disable') && values.value ) {

							form.submit();

						}

					})


				}
				formSubmit();

			// -- END -- //


			// -- Select resize -- //

				function selectResize() {

					var select = container.querySelector('.CustomerForm__select');

					recalcSelectSize( select );

					select.addEventListener('change', function() {

						recalcSelectSize( select );

					});

					window.addEventListener('resize', function() {

						recalcSelectSize( select );

					});

				}
				selectResize();

				var tempWidth;
				var text = '';
				function recalcSelectSize( select ) {

					text = '';

					select.querySelectorAll('option').forEach( function( single,index ) {

						if( single.getAttribute('value') == select.value ) {

							container.querySelector('input[name="careCategory"]').value = single.getAttribute('data-id');
							text = single.innerText;

						}

					});

			        // create test element
			        var temp = $("<span>").html(text).css({
			        	"font-size": parseFloat(getComputedStyle(select)['fontSize'] ), 
			        	"visibility": "hidden",
			        	"width" : "fit-content",
			        	"font-weight": "700",
			        	"font-family" : "Arial",
			        	"display": "block",
			        	"width" : "100%",
			        	"white-space" : "nowrap",
			        });
			        

			        // add to body, get width, and get out
			        temp.appendTo( select.parentNode );
			        tempWidth = temp.innerWidth();
			        temp.remove();
			        // set select width
			        select.style.width = tempWidth + parseFloat(getComputedStyle(select)['paddingRight'] ) + parseFloat(getComputedStyle(select)['paddingLeft'] ) + 'px';


				}

			// -- END -- //

		}

	},
	finalize() {

	}

}