import BoxMobileSlider from '../../Sections/BoxMobileSlider/BoxMobileSlider';
import OpinionSection from '../../Sections/OpinionSection/OpinionSection';
import NewsletterSection from '../../Sections/NewsletterSection/NewsletterSection';
import lozad from 'lozad';
import $ from 'jquery';

export default {

	init() {

		BoxMobileSlider.init();
		OpinionSection.init();
		NewsletterSection.init();

		var container = document.querySelector('main.ArchiveProducts');

		if( container ) {

			var timeout = null;

			// -- Generate new url -- //

				var params = [];
				var symptoms = [];
				var terms = [];
				var states = [];
				var priceMin = container.querySelector('input[name="price_min"]');
				var priceMax = container.querySelector('input[name="price_max"]');
				var newUrl = window.location.origin + window.location.pathname;
				function generateNewUrl() {

					params = [];
					symptoms = [];
					terms = [];
					states = [];
					newUrl = window.location.origin + window.location.pathname;

					if( container.querySelectorAll('.header__symptoms .symptoms__single').length > 0 ) {

						container.querySelectorAll('.header__symptoms .symptoms__single').forEach( function(single,index) {

							symptoms.push( single.getAttribute('data-id') );

						});	

						if( symptoms.length > 0 ) {

							params.push('symptoms='+ symptoms.join(',') );

						}

					}

					if( container.querySelectorAll('.side__filter ul li input').length > 0 ) {

						container.querySelectorAll('.side__filter ul li input').forEach( function(input,index) {

							if( input.checked ) {

								terms.push(input.value);

							}

						});

						if( terms.length > 0 ) {

							params.push('types='+terms.join(',') );

						}

					}

					if( container.querySelectorAll('.side__filter--search .selected__single:not(.selected__single--hide)').length > 0 ) {

						container.querySelectorAll('.side__filter--search .selected__single:not(.selected__single--hide)').forEach( function( single,index ) {

							states.push( single.getAttribute('data-id') );

						});

						if( states.length > 0 ) {

							params.push('states='+states.join(',') );

						}

					}

					if( container.getAttribute('data-care_category') ) {

						params.push( 'careCategory='+container.getAttribute('data-care_category') );

					}

					if( priceMin.value ) {

						params.push('price_min='+priceMin.value);

					}

					if( priceMax.value ) {

						params.push('price_max='+priceMax.value);

					}	

					params.push('page_num='+container.getAttribute('data-page_num') );

					if( params.length > 0 ) {

						newUrl = newUrl+'?'+params.join('&');

					}

					return newUrl;

				}

			// -- END -- //


			// -- Reload content -- //

				var xhr = new XMLHttpRequest();
				var xmlDoc = null;

				function archiveProductsRefreshContent() {

					xhr = new XMLHttpRequest();

					xhr.onreadystatechange = function() {

						if( xhr.readyState == 4 && xhr.status == 200) {

	            			var xmlDoc = document.implementation.createHTMLDocument("example");
							xmlDoc.documentElement.innerHTML = xhr.responseText;

							container.querySelectorAll('.content__products .products--reload').forEach( function( box,index ) {

								box.remove();

							});

							xmlDoc.querySelectorAll('.content__products .products--reload').forEach( function( box,index ) {

								container.querySelector('.content__products').insertBefore(box, container.querySelector('.content__products').lastChild.nextSibling );

							});

							var pictureObserverAfterLoadDOM = lozad('.lazy-load-item', {
				    			threshold: 0
							});
							pictureObserverAfterLoadDOM.observe();


							container.querySelector('.header__title span').innerText = xmlDoc.querySelector('.header__title span').innerText;

	        			} 

					}

					xhr.open( 'GET', generateNewUrl()+'&ajax=1', true );
					xhr.send();

				}

				// archiveProductsRefreshContent();

			// -- END -- //


			// -- Price Action -- //

				function rangeValueChange() {

					var rangeMin = container.querySelector('input[name="price_min"]');
					var rangeMax = container.querySelector('input[name="price_max"]');

					var priceMin = container.querySelector('.price__value--min');
					var priceMax = container.querySelector('.price__value--max');

					var valueMin = parseFloat( rangeMin.getAttribute('min') );
					var valueMax = parseFloat( rangeMin.getAttribute('max') );

					var containerWidth = 0;
					var rangeContainer = container.querySelector('.filter__value');
					containerWidth = parseFloat(getComputedStyle(rangeContainer)['width']);

					var percent = 0;

					rangeMin.addEventListener('input', function() {

						clearInterval( timeout );

						if( rangeMin.value > parseInt(rangeMax.value) - 1 ) {

							rangeMin.value = parseInt(rangeMax.value) - 1;

						}

						priceMin.querySelector('span').innerText = rangeMin.value;

						percent = rangeMin.value * 100 / valueMax;

						if( percent < 50 ) {

							priceMin.style.left = containerWidth * percent / 100 + ( 10 * (50 - percent) ) / 50 + 'px';

						} else {

							priceMin.style.left = containerWidth * percent / 100 - ( 7 * (percent - 50) ) / 50 + 'px';

						}

						if( parseFloat(getComputedStyle(priceMin)['left']) < 10 ) {

							priceMin.style.left = '10px';							

						}

						if( parseFloat(getComputedStyle(priceMin)['left']) > (containerWidth - 7) ) {

							priceMin.style.left = (containerWidth - 7) + 'px';

						}

						timeout = setTimeout(
							function() {
								container.setAttribute('data-page_num',1);
								archiveProductsRefreshContent();
							}, 
							500
						);
							

					});

					rangeMax.addEventListener('input', function() {

						clearInterval( timeout );

						if( rangeMax.value < parseInt( rangeMin.value ) + 1 ) {

							rangeMax.value = parseInt( rangeMin.value ) + 1;

						}

						priceMax.querySelector('span').innerText = rangeMax.value;

						percent = (rangeMax.value * 100 )/ valueMax;

						if( percent > 50 ) {

							priceMax.style.right = containerWidth - ( containerWidth * percent / 100 - ( 7 * (percent - 50) ) / 50) + 'px';

						} else {

							priceMax.style.right = containerWidth - ( containerWidth * percent / 100 + ( 10 * (50 - percent) ) / 50) + 'px';

						}

						console.log( percent );


						timeout = setTimeout(
							function() {
								container.setAttribute('data-page_num',1);
								archiveProductsRefreshContent();
							}, 
							500
						);

					});

					window.addEventListener('resize', function() {

						containerWidth = parseFloat(getComputedStyle(rangeContainer)['width']);

					});

				}
				rangeValueChange();

			// -- END -- //


			// -- Symptoms Action -- //

				var symptomsBox = container.querySelector('.header__symptoms');

				if( symptomsBox ) {

					var symptomsButton = symptomsBox.querySelectorAll('.symptoms__single');
				
					symptomsButton.forEach( function( button,index ) {

						button.addEventListener('click', function() {

							button.remove();
							container.setAttribute('data-page_num',1);
							archiveProductsRefreshContent();

							if( container.querySelectorAll('.header__symptoms .symptoms__single').length == 0 ) {

								symptomsBox.remove();

							}

						});

					});

				}

			// -- END -- //


			// -- TermsChange -- //

				function archiveProductsTermsChange() {

					var termList = container.querySelectorAll('.side__filter ul li input');

					termList.forEach( function(input,index) {

						input.addEventListener('change', function() {

							container.setAttribute('data-page_num',1);
							archiveProductsRefreshContent();

						});

					});

				}
				archiveProductsTermsChange();

			// -- END -- //


			// -- StateSearch -- //

				function archiveProductsStateSearch() {

					var searchContainer = container.querySelector('.side__filter--search');
					var searchClear = searchContainer.querySelector('.search__clear');
					var input = searchContainer.querySelector('input.search__cell');
					var newItemsContainer = searchContainer.querySelector('.filter__selected');
					var itemToClone = newItemsContainer.querySelector('.selected__single--hide');

					var list = searchContainer.querySelector('.search__list');
					var items = list.querySelectorAll('.search__cell');

					input.addEventListener('input', function() {

						clearInterval( timeout );

						if( input.value ) {

							list.classList.add('active');

						} else {

							list.classList.remove('active');

						}

						timeout = setTimeout(
							function() {

								items.forEach( function( single,index ) {

									if( 
										single.innerText.search( input.value ) != -1 || 
										single.innerText.search( input.value.toUpperCase() ) != -1 ||
										single.innerText.search( input.value.toLowerCase() ) != -1 ||
										single.innerText.search( input.value.charAt(0).toUpperCase() + input.value.slice(1) ) != -1
									) {

										single.classList.add('active');

									} else {

										single.classList.remove('active');

									}

								});

							}, 500
						);

					});

					var newElement = null;
					items.forEach( function( item,index ) {

						item.addEventListener('click', function() {

							newElement = itemToClone.cloneNode(true);
							newElement.querySelector('p').innerText = item.innerText;
							newElement.setAttribute('data-id', item.getAttribute('data-value') );
							newElement.classList.remove('selected__single--hide');

							newItemsContainer.appendChild(newElement);

							input.value = '';
							list.classList.remove('active');

							container.setAttribute('data-page_num',1);

							if( searchClear.classList.contains('hide') ) {
								searchClear.classList.remove('hide');
							}

							archiveProductsRefreshContent();

						});

					});

					newItemsContainer.addEventListener('click', function(e) {

						if( e.target.classList.contains('selected__single') && !e.target.classList.contains('selected__single--hide') ) {

							e.target.remove();
							container.setAttribute('data-page_num',1);
							archiveProductsRefreshContent();

						}

						if( newItemsContainer.querySelectorAll('.selected__single:not(.selected__single--hide)').length == 0 ) {

							searchClear.classList.add('hide');

						}

					});

					searchClear.addEventListener('click', function() {

						newItemsContainer.querySelectorAll('.selected__single:not(.selected__single--hide)').forEach( function( item,index ) {

							item.remove();

						});

						searchClear.classList.add('hide');
						container.setAttribute('data-page_num',1);
						archiveProductsRefreshContent();

					});

				}
				archiveProductsStateSearch();

			// -- END -- //


			// -- Paged -- //

				function archiveProductsPaged() {

					var header = document.querySelector('header');

					document.addEventListener('click', function( e ) {

						if( e.target.classList.contains('pages__single') && !e.target.classList.contains('active') ) {

							container.setAttribute('data-page_num', e.target.innerText);
							archiveProductsRefreshContent();

						}

						if( e.target.classList.contains('pagination__button--prev') ) {

							container.setAttribute('data-page_num', parseInt( container.getAttribute('data-page_num') ) -1 );
							archiveProductsRefreshContent();

						}

						if( e.target.classList.contains('pagination__button--next') ) {

							container.setAttribute('data-page_num', parseInt(container.getAttribute('data-page_num') ) +1 );
							archiveProductsRefreshContent();
							

						}

						if( 
							e.target.classList.contains('pagination__button--next') ||
							e.target.classList.contains('pagination__button--prev') ||
							( e.target.classList.contains('pages__single') && !e.target.classList.contains('active') )
						) {

							$('html ,body').animate({'scrollTop': (container.querySelector('.ArchiveProduts__content').offsetTop - header.offsetHeight) }, 'smooth');

						}

					});

				}
				archiveProductsPaged();

			// -- END -- //


			// -- Reset -- //

				function archiveProductsResetButton() {

					document.addEventListener('click', function(e) {

						if( e.target.classList.contains('button__reset') || e.target.closest('.button__reset') ) {

							// -- price -- //

								container.querySelector('input[name="price_min"]').value = container.querySelector('input[name="price_min"]').getAttribute('min');
								container.querySelector('input[name="price_max"]').value = container.querySelector('input[name="price_max"]').getAttribute('max');

								container.querySelector('.price__value--min span').innerText = container.querySelector('input[name="price_min"]').getAttribute('min');
								container.querySelector('.price__value--min').style.left = '10px';
								container.querySelector('.price__value--max span').innerText = container.querySelector('input[name="price_max"]').getAttribute('max');
								container.querySelector('.price__value--max').style.right = '7px';

							// -- END -- //


							// -- state -- //

								container.querySelectorAll('.side__filter--search .filter__selected .selected__single:not(.selected__single--hide)').forEach( function(item,index) {

									item.remove();

								});

								container.querySelector('.side__filter--search .search__clear').classList.add('hide');

							// -- END -- //


							// -- terms -- //

								container.querySelectorAll('.side__filter--list ul li input[type="checkbox"]').forEach( function(input,index) {

									input.checked = false;

								});

							// -- END -- //

							container.setAttribute('data-page_num',1);
							archiveProductsRefreshContent();

						}

					});

				}
				archiveProductsResetButton();


			// -- END -- //

		}

	},	
	finalize() {

	}

}