export default {

	init() {

		var container = document.querySelector('section.ContactSection');

		if( container ) {

			// -- input actions -- //

				function inputActions() {

					var fields = container.querySelectorAll('.form__field input');

					fields.forEach( function( input,index ) {

						input.addEventListener( 'focus', function() {

							input.classList.add('labelTop');
							input.classList.add('active');

						});

						input.addEventListener('blur', function() {

							if( !input.value ) {

								input.classList.remove('labelTop');

							}

							input.classList.remove('active');

						});

					});

				}	
				inputActions();

			// -- END -- //


			// -- Submit form -- //

				function submitForm() {

					var form = container.querySelector('form.ContactSection__form');
					var submitButton = form.querySelector('.Button');
					var formFields = form.querySelectorAll('.form__field');

					var formSubmit = false;

					var formError = form.querySelector('.form__error');
					var formSuccess = form.querySelector('.form__success');

					var response;
					var request = new XMLHttpRequest();
					var requestData = [];

					submitButton.addEventListener('click', function() {

						if( !submitButton.classList.contains('disable') && !formSubmit ) {

							submitButton.classList.add('disable');

							formFields.forEach( function( field,inde ) {

								if( field.querySelector('input').classList.contains('required') ) {

									if( field.querySelector('input').getAttribute('type') == 'checkbox' ) {

										if( !field.querySelector('input').checked ) {

											field.classList.add('empty');

										} else {

											field.classList.remove('empty');

										}

									} else {

										if( !field.querySelector('input').value ) {

											field.classList.add('empty');

										} else {

											field.classList.remove('empty');

										}

										if( field.querySelector('input').getAttribute('type') == 'email' && field.querySelector('input').value ) {

											if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( field.querySelector('input').value ) ) {

												field.classList.remove('error');

											} else {

												field.classList.add('error');

											}

										}

									}

								}

							});

							if( form.querySelectorAll('.form__field.empty').length == 0 && form.querySelectorAll('.form__field.error').length == 0 ) {

								requestData = [];

								requestData.push( 'action=sendMail');
								requestData.push( 'security='+form.querySelector('input#authorizeContactForm').value );
								requestData.push( 'type='+form.querySelector('input[name="type"]').value );

								formFields.forEach( function( field,index ) {

									if( field.querySelector('input').getAttribute('type') != 'checkbox' ) {

										requestData.push( field.querySelector('input').getAttribute('name') +'='+ field.querySelector('input').value );

									}

								});

								requestData = requestData.join('&');

								request.onreadystatechange = function() {

									if( request.responseText ) {

										response = JSON.parse( request.responseText );

										if( response.status == 'success' ) {
											
											formSuccess.classList.add('active');
											formSubmit = true;

										} else if( response.status == 'error' ) {

											formError.classList.add('active');
											submitButton.classList.remove('disable');

										}

									}

								}

								// Set up our request
								request.open('POST', form.getAttribute('data-endpoint') );
								// Add the required HTTP header for form data POST requests
								request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
								// Finally, send our data.
								request.send( requestData );

							} else {

								submitButton.classList.remove('disable');

							}

						}

					});

					form.addEventListener('submit', function(e) {											

						e.preventDefault();

					});

				}
				submitForm();

			// -- END -- //

		}

	},
	finalize() {

	}

}