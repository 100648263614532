import $ from "jquery";
import slick from "slick-carousel";

export default {

	init() {

		var container = document.querySelector('section.BoxMobileSlider');

		if( container ) {

			function slickCarousel() {

				var slickStart = false;
				var carousel = container.querySelector('.BoxMobileSlider__slider');

				$(carousel).slick({
					slidesToShow : 1,
					slidesToScroll : 1,
					arrows : false,
					centerMode: false,
					rows: false,
					variableWidth: true,
					autoplay: true,
					autoplaySpeed: 2000,
				});

			}

			slickCarousel();

		}

	},
	finalize() {

	}

}