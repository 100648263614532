import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('.ProductsSection');

		if( container ) {

			function productsSectionSlick() {

				var carousel = container.querySelector('.ProductsSection__items');
				var buttonNext = container.querySelector('.SlickControl__button--next');
				var buttonPrev = container.querySelector('.SlickControl__button--prev');

				var carouselValueContent = container.querySelector('.paged__value');
				var carouselValue = parseInt( carouselValueContent.innerText );
				
				var carouselMaxContent = container.querySelector('.paged__max');
				var carouselMax = parseInt( carouselMaxContent.innerText );

				var carouselItems = parseInt( carousel.getAttribute('data-count') );


				$(carousel).slick({
					slidesToShow: 4,
					slidesToScroll: 4,
					rows: false,
					arrows: false,
					responsive: [
						{
							breakpoint: 1361,
							settings: {							
								slidesToShow: 3,
								slidesToScroll: 3,
							}
						},
						{
							breakpoint: 961,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2,
							}
						},
						{
							breakpoint: 765,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							}
						},
						{
							breakpoint: 661,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2,
								dots: true,
							}
						},
						{
							breakpoint: 341,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								autoplay: true,
								autoplaySpeed: 2000,
							}
						}
					]
				});

				// -- slick change slides -- //

					function slickSlidesNext() {

						carouselValue += 1;

						if( carouselValue > carouselMax ) {

							carouselValue = 1;

						} 

						carouselValueContent.innerText = carouselValue;

					}

					function slickSlidesPrev() {

						carouselValue -= 1;

						if( carouselValue <= 0 ) {

							carouselValue = carouselMax;

						}

						carouselValueContent.innerText = carouselValue;

					}

					buttonNext.addEventListener('click', function() {

						$(carousel).slick('slickNext');

						// slickSlidesNext();

					});

					buttonPrev.addEventListener('click', function() {

						$(carousel).slick('slickPrev');

						// slickSlidesPrev();

					});	

					$(carousel).on('beforeChange', function( event, slick, currentSlide, nextSlide ) {

						if( currentSlide < nextSlide ) {

							slickSlidesNext();

						} else if( nextSlide == 0 ) {

							carouselValue = 1;
							carouselValueContent.innerText = carouselValue;

						} else {

							slickSlidesPrev();

						}

					});

				// -- END -- //


				// -- slick resize -- //

					function debounce(func, wait, immediate) {
						var timeout;
						return function() {
							var context = this, args = arguments;
							var later = function() {
								timeout = null;
								if (!immediate) func.apply(context, args);
							};
							var callNow = immediate && !timeout;
							clearTimeout(timeout);
							timeout = setTimeout(later, wait);
							if (callNow) func.apply(context, args);
						};
					};

					function resizeRecal() {

						if( window.innerWidth < 765 ) {

							carouselMax = carouselItems;

						} else if( window.innerWidth < 961 ) {

							carouselMax = Math.ceil( carouselItems / 2 );

						} else if( window.innerWidth < 1361 ) {

							carouselMax = Math.ceil( carouselItems / 3 );
 
						} else {

							carouselMax = Math.ceil( carouselItems / 4 );

						}	

						$(carousel).slick('slickGoTo', 1);
						carouselValue = 1;
						carouselValueContent.innerText = carouselValue;
						carouselMaxContent.innerText = carouselMax;

					}
					resizeRecal();

					var productsSectionSlickResize = debounce(function() {

						resizeRecal();

					}, 250);

					window.addEventListener('resize', productsSectionSlickResize);

				// -- END -- //

			}	

			productsSectionSlick();


		}

	},
	finalize() {

	}

}