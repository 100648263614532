import ContactSection from '../../Sections/ContactSection/ContactSection'

export default {

	init() {

		ContactSection.init();

	},
	finalize() {

	}

}